.amplify-table__th {
  background-color: var(--amplify-colors-orange-60);
}

.amplify-table__head {
    background-color: var(--amplify-colors-orange-90);
}

.amplify-table__th:first-child {
  text-align: right;
}

.amplify-table__row:not(:first-child) .amplify-table__th {
  border-top: none;
}

.amplify-table__row:not(:last-child) .amplify-table__th {
  border-bottom: none;
}

.amplify-table__caption {
  caption-side: top;
  text-align: right;
}

.table-summary {
  color: var(--amplify-colors-font-secondary);
  font-style: italic;
}

.amplify-table__td {
    color: var(--amplify-colors-font-inverse);
}

:root, [data-amplify-theme] {
  --amplify-components-table-row-hover-background-color: var(--amplify-colors-neutral-90);
  --amplify-components-table-row-striped-background-color: var(--amplify-colors-neutral-100);
}

.my-link {
    color: var(--amplify-colors-orange-60);
}

.my-link:hover {
    color: var(--amplify-colors-orange-80);
}
