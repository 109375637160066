/* styles.css */
.accordion-trigger {
  color: #333; /* Dark grey color */
  text-align: left;
}

.accordion-content {
  text-align: left;
}

.notes-select {
    color: #333;
}
