.amplify-accordion {
  --amplify-components-accordion-background-color: var(--amplify-colors-overlay-10);
}

.min-slider .amplify-sliderfield__track {
    background-color: var(--amplify-colors-orange-60);
}

.min-slider .amplify-sliderfield__range {
    background-color: var(--amplify-colors-neutral-80);
}

.max-slider .amplify-sliderfield_track {
    background-color: var(--amplify-colors-neutral-80);
}

.max-slider .amplify-sliderfield__range {
    background-color: var(--amplify-colors-orange-60);
}

.amplify-autocomplete {
    --amplify-components-autocomplete-menu-option-active-background-color: var(--amplify-colors-neutral-80);
    --amplify-components-autocomplete-menu-background-color: var(--amplify-colors-neutral-80);
}

.amplify-autocomplete input {
    color: var(--amplify-colors-neutral-40);
}
