/* App.css */
#root {
  text-align: center;
  margin: 1.2rem;
}

nav {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
[data-amplify-authenticator] [data-amplify-router] fieldset {
  display: none;
}
[data-amplify-authenticator] .amplify-divider {
  display: none;
}
[data-amplify-authenticator] form div:not(.federated-sign-in-container) button {
  display: none;
}
[data-amplify-authenticator] form + [data-amplify-footer] {
  display: none;
}
/* doesnt work in v6*/
/* .amplify-tabs { */
/*   display: none; */
/* } */

/* App.css (or a separate CSS file) */
.dashboard-container {
  display: grid;
  grid-template-columns: 30% 70%; /* Define a 30% - 70% column layout */
  gap: 20px; /* Adjust the gap between the components as needed */
  align-items: start; /* Align items at the start (top of the container) */
}

/* Add any other styling as needed */

.amplify-checkbox__label {
    color: #b9b5b2 !important;
}

.amplify-switch__label {
    color: #b9b5b2 !important;
}


.amplify-label {
  /* color: var(--amplify-components-field-label-color); */
    color: #b9b5b2 !important;
}

:root {
    --amplify-components-checkbox-button-disabled-border-color: #433f3d;
    --amplify-components-checkbox-icon-checked-disabled-background-color: #433f3d;
    --amplify-components-checkbox-icon-background-color: #70AF55;
    --amplify-components-switchfield-track-checked-background-color: #70AF55;
}
