@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,500);

body { 
  background-color: #1b1c1d; 
  color: #f46f22;
  font-size: 100%;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
}

.button {
  font-weight: 300;
  color: #f46f22;
  font-size: 1.2em;
  text-decoration: none;
  border: 1px solid #f46f22;
  padding: .5em;
  border-radius: 3px;
  float: center;
  /* margin: 6em 0 0 -155px; */
  /* position: relative; */
  transition: all .3s linear;
}

.button:hover {
  background-color: #f46f22;
  color: #fff;
}

p {
  font-size: 2em;
  text-align: center;
  font-weight: 100;
}

h1 {
  text-align: center;
  font-size: 15em;
  font-weight: 100;
  text-shadow: #1b0000 1px 1px, #1b0000 2px 2px, #1b0000 3px 3px, #1b0000 4px 4px, #1b0000 5px 5px, #1b0000 6px 6px, #1b0000 7px 7px, #1b0000 8px 8px, #1b0100 9px 9px, #1b0100 10px 10px, #1b0100 11px 11px, #1b0100 12px 12px, #1b0100 13px 13px, #1b0100 14px 14px, #1b0100 15px 15px, #1b0100 16px 16px, #1b0200 17px 17px, #1b0200 18px 18px, #1b0200 19px 19px, #1b0201 20px 20px, #1b0201 21px 21px, #1b0201 22px 22px, #1b0201 23px 23px, #1b0202 24px 24px, #1b0302 25px 25px, #1b0302 26px 26px, #1b0302 27px 27px, #1b0303 28px 28px, #1b0303 29px 29px, #1b0303 30px 30px, #1b0303 31px 31px, #1b0304 32px 32px;
}
