/* Add this CSS to your stylesheet or within a style tag in your React component */

form {
  display: flex;
  justify-content: center;
}

.column-container {
  display: flex;
  margin-top: 20px; /* Adjust as needed */
}

.column {
  margin-right: 20px; /* Adjust as needed */
}

.error-message-container {
    text-align: center;
    margin-top: 20px; /* Adjust as needed */
}

.button-container {
    text-align: center;
    margin-top: 10px; /* Adjust as needed */
}
