.amplify-textfield {
    text-align: left;
    --amplify-components-textfield-color: #b9b5b2;
    --amplify-components-textfield-focus-border-color: var(--amplify-colors-orange-80);
}

.amplify-searchfield {
    --amplify-components-searchfield-color: var(--amplify-colors-neutral-80);
}

.amplify-searchfield input {
    color: var(--amplify-colors-neutral-40);
}
